<template>
  <div class="phone-bar">
    <div class="peerStatus">
      <el-select
        v-model="agentStatusNumber"
        placeholder="切换座席状态"
        style="margin-right: 10px"
        size="mini"
        @change="updateAgentStatus"
      >
        <el-option
          v-for="(item, index) in agentStatusList"
          :key="index"
          :label="item.name"
          :value="item.number"
        />
      </el-select>
      <span id="phoneAgentStatus">{{ currentStatusName }}</span>
      <span class="softphone_timer">
        <PhoneBarTimer ref="phoneBarTimeRef" />
      </span>
      <el-select
        v-model="loginType"
        placeholder="切换登陆方式"
        size="mini"
        @change="updateLoginType"
      >
        <el-option
          v-for="(item, index) in loginTypeList"
          :key="index"
          :disabled="item.value === 'SIPAPP'"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button
        class="webphone-btn"
        type="primary"
        :disabled="!isVisibleBtn('disconnect')"
        size="mini"
        @click="disconnect"
      >断开设备</el-button>
      <el-button
        class="webphone-btn"
        type="primary"
        :disabled="!isVisibleBtn('connect')"
        size="mini"
        @click="connect"
      >重连设备</el-button>
    </div>
    <div class="callStatus">
      <el-input
        v-model.trim="dialoutNumber"
        style="width: 180px; margin-right: 10px"
        placeholder="请输入外呼号码"
        @keyup.enter.native="keyDowndialout"
      />
      <div class="callBtnList">
        <el-button
          type="primary"
          :disabled="!isVisibleBtn('dialout')"
          @click="dialout"
        >外呼</el-button>
        <el-button
          type="primary"
          :disabled="!isVisibleBtn('hangup')"
          @click="hangup"
        >挂机</el-button>
        <el-button
          type="primary"
          :disabled="!isVisibleBtn('hold')"
          @click="holdOrUnHold('1')"
        >保持</el-button>
        <el-button
          type="primary"
          :disabled="!isVisibleBtn('holdcancel')"
          @click="holdOrUnHold('2')"
        >取消保持</el-button>
        <el-button
          type="primary"
          :disabled="!isVisibleBtn('mute')"
          @click="muteOrUnMute('1')"
        >静音</el-button>
        <el-button
          type="primary"
          :disabled="!isVisibleBtn('mutecancel')"
          @click="muteOrUnMute('2')"
        >取消静音</el-button>
        <el-button
          type="primary"
          :disabled="!isVisibleBtn('answer')"
          @click="accept"
        >接听</el-button>
      </div>
    </div>
    <div class="peerStatus">
      <el-select
        v-model="agentNumber"
        placeholder="选择座席"
        style="margin-right: 10px"
        @change="updateAgent"
        size="mini"
      >
        <el-option
          v-for="(item, index) in agentList"
          :key="index"
          :label="item.agentName"
          :value="item.agentNumber"
        />
      </el-select>
      <el-button
        class="webphone-btn"
        type="primary"
        size="mini"
        @click="tranfer('agent')"
        :disabled="!isVisibleBtn('transfer')"
      >转接座席</el-button>

      <el-select
        v-model="ivrNumber"
        placeholder="选择ivr"
        style="margin-right: 10px; margin-left: 10px"
        size="mini"
      >
        <el-option
          v-for="(item, index) in ivrList"
          :key="index"
          :label="item.name"
          :value="item.nodeId"
        />
      </el-select>
      <el-button
        class="webphone-btn"
        type="primary"
        size="mini"
        @click="tranfer('ivr')"
        :disabled="!isVisibleBtn('transfer')"
      >转接IVR</el-button>

      <el-input
        v-model="outlineNumber"
        style="width: 180px; margin-right: 10px; margin-left: 10px"
        placeholder="请输入外线号码"
        @keyup.enter.native="keyDowndialout"
      />

      <el-button
        class="webphone-btn"
        type="primary"
        size="mini"
        @click="tranfer('outline')"
        :disabled="!isVisibleBtn('transfer')"
      >转接外线电话</el-button>
    </div>
    <div class="dtmf">
      <div class="dtmf-tit">webrtc拨号盘</div>
      <div class="dtmf-box">
        <el-button
          v-for="key in keyList"
          :key="key"
          type="primary"
          :disabled="!isVisibleBtn('key')"
          @click="sendDTMF(key)"
        >{{ key }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneBarTimer from './phoneBarTimer'
// import SPhone from 'sphone-sdk'
import { Message } from 'element-ui'
export default {
  name: 'phoneBar',
  components: {
    PhoneBarTimer
  },
  props: {
    loginSuccess: Boolean,
    loginInfo: Object
  },
  data() {
    return {
      dialoutNumber: '',
      renderMap: {
        PSTN_INVALID: ['dialout'],
        PSTN_INCOMING_CALLING: ['hangup'],
        PSTN_DIALOUT_CALLING: ['hangup'],
        PSTN_INCOMING_RING: ['hangup'],
        PSTN_DIALOUT_RING: ['hangup'],
        PSTN_INCOMING_LINK: ['hangup', 'hold', 'transfer', 'mute'],
        PSTN_DIALOUT_LINK: ['hangup', 'hold', 'transfer', 'mute'],
        PSTN_INCOMING_MUTE: ['mutecancel'],
        PSTN_DIALOUT_MUTE: ['mutecancel'],
        PSTN_INCOMING_HOLD: ['holdcancel'],
        PSTN_DIALOUT_HOLD: ['holdcancel'],
        PSTN_INCOMING_ARRANGE: ['dialout'],
        PSTN_DIALOUT_ARRANGE: ['dialout'],
        //
        SIP_INVALID: ['dialout'],
        SIP_INCOMING_CALLING: ['hangup'],
        SIP_DIALOUT_CALLING: ['hangup'],
        SIP_INCOMING_RING: ['hangup'],
        SIP_DIALOUT_RING: ['hangup'],
        SIP_INCOMING_LINK: ['hangup', 'hold', 'transfer', 'mute'],
        SIP_DIALOUT_LINK: ['hangup', 'hold', 'transfer', 'mute'],
        SIP_INCOMING_MUTE: ['mutecancel'],
        SIP_DIALOUT_MUTE: ['mutecancel'],
        SIP_INCOMING_HOLD: ['holdcancel'],
        SIP_DIALOUT_HOLD: ['holdcancel'],
        SIP_INCOMING_ARRANGE: ['dialout'],
        SIP_DIALOUT_ARRANGE: ['dialout'],
        //
        WEBRTC_INVALID: ['dialout', 'disconnect'],
        WEBRTC_BUSY: ['dialout', 'disconnect'],
        WEBRTC_INCOMING_CALLING: ['hangup'],
        WEBRTC_TRANSFER__RING: ['hangup', 'answer', 'animation'],
        WEBRTC_TRANSFER_LINK: ['hangup', 'hold', 'transfer', 'mute', 'transfer'],
        WEBRTC_TRANSFER_MUTE: ['hangup', 'mutecancel'],
        WEBRTC_TRANSFER_HOLD: ['hangup', 'holdcancel'],
        WEBRTC_TRANSFER_ARRANGE: ['dialout'],
        WEBRTC_DIALOUT_CALLING: ['hangup'],
        WEBRTC_INCOMING_RING: ['hangup', 'answer'],
        WEBRTC_DIALOUT_RING: ['hangup'],
        WEBRTC_INCOMING_LINK: ['hangup', 'hold', 'transfer', 'mute', 'key'],
        WEBRTC_DIALOUT_LINK: ['hangup', 'hold', 'transfer', 'mute', 'key'],
        WEBRTC_INCOMING_MUTE: ['mutecancel'],
        WEBRTC_DIALOUT_MUTE: ['mutecancel'],
        WEBRTC_INCOMING_HOLD: ['holdcancel'],
        WEBRTC_DIALOUT_HOLD: ['holdcancel'],
        WEBRTC_INCOMING_ARRANGE: ['dialout'],
        WEBRTC_DIALOUT_ARRANGE: ['dialout'],
        WEBRTC_DISCONNECTED: ['connect']
      },
      currentStatus: '',
      currentStatusName: '未连接',
      keyList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'],
      loginType: '',
      loginTypeMap: {
        PSTN:'手机',
        SIP:'SIP话机',
        WEBRTC:'webrtc'
      },
      loginTypeList: [],
      agentStatusList: [],
      agentStatusNumber: '',
      agentList: [],
      agentNumber: '',
      ivrList: [],
      ivrNumber: '',
      outlineNumber: ''
    }
  },
  watch: {
    loginSuccess(newVal) {
      if (newVal) {
        this.getLoginTypeOptions()
        this.getAgentPhoneBarList()
        this.getAvailableSipNumberList()
        this.getQueueOnlineAgents()
        this.getIvrList()
      }
    }
  },
  methods: {
    reSetLogin() {
      window.SPhone = {}
      this.$emit('handleLogin', null)
      this.currentStatus = ''
      this.currentStatusName = '未连接'
      this.loginType = ''
      this.loginTypeList = []
      this.agentStatusList = []
      this.agentStatusNumber = ''
      this.agentList = []
      this.agentNumber = ''
      this.ivrList = []
      this.ivrNumber = ''
      this.outlineNumber = ''
      this.$refs.phoneBarTimeRef.clearPhonebarTimer()
    },
    tranfer(mode) {
      let number = ''
      if(mode === 'agent') {
        number = this.agentNumber
      } else if (mode === 'ivr') {
        number = this.ivrNumber
      } else if (mode === 'outline') {
        number = this.outlineNumber
      }
      window.SPhone.callApi.transfer({
        agentNumber:this.loginInfo.agent.agentNumber,
        number: number,
        mode: mode,
        success: (res) => {
          console.log(res)
        },
        error: (error) => {
          console.error(error)
        },
      })
    },
    updateAgent() {
      console.log('updateAgent', this.agentNumber)

    },
    getQueueOnlineAgents() {
      window.SPhone.agentApi.getQueueOnlineAgents({
        success: (res) => {
          res.dataList.forEach(item=>{
            item.members.forEach(item2=>{
              const agent = {
                agentName: item.name + '-' +  item2.agentName,
                agentNumber : item2.agentNumber,
                agentId: item2.agentId
              }
              this.agentList.push(agent)
            })
          })
        },
        error: (error) => {
          console.error(error)
        },
      })
    },
    getIvrList() {
      window.SPhone.agentApi.getIvrList({
        types: ["ivrList"],
        success: (res) => {
          console.log('getIvrList', res)
          this.ivrList = res.dataList
        },
        error: (error) => {
          console.error(error)
        },
      })
    },
    attachEvent() {
      window.SPhone.attachEvent({
        error: (error) => {
          console.error(error)
          Message({
              message: '您当前的会话已经失效',
              type: 'error',
              duration: 5 * 1000
            })
            this.reSetLogin()
        },
        message: (res) => {
          const event = res.event
          console.log(res)
          if (res.type === 'KICK_OFF') {
            Message({
              message: '您当前的会话已经失效,导致该问题的原因是别的座席使用相同的帐号登录了',
              type: 'error',
              duration: 5 * 1000
            })
            this.reSetLogin()
            return
          } else if (res.type === 'callStatus') {
            this.currentStatusName = event.statusName
            this.agentStatusNumber = event.statusName
            const timeType = event.statusNumber === '5' ? 'countDown' : 'timing'
            if (event.statusTime) {
              this.timeRecording(timeType, event.statusTime)
            } else {
              this.timeRecording(timeType)
            }
          }
          this.currentStatus = this.getCurrentStatus(event.statusNumber, event.callType)
        }
      })
      window.SPhone.initAttachEvent(this.loginType, this.loginInfo.pushServer, this.loginInfo.agent)
    },
    getLoginTypeOptions() {
      const loginTypeArr = this.loginInfo.agent.agentCallConfig.loginType
      this.loginTypeList = []
      loginTypeArr.forEach(item => {
        this.loginTypeList.push({
          value: item,
          label: this.loginTypeMap[item]
        })
      });
      this.loginType = this.loginTypeList[0].value
      this.attachEvent()
    },
    dialout() {
      if (!this.dialoutNumber) {
        Message({
          message: '请填写外呼号码',
          type: 'error',
          duration: 5 * 1000
        })
        return
      }
      if (window.SPhone) {
        window.SPhone.callApi.dialout({
          calleeNumber: this.dialoutNumber,
          success: function() {
            Message({
              message: '外呼成功',
              type: 'success',
              duration: 5 * 1000
            })
          },
          fail: function(error) {
            Message({
              message: error.message,
              type: 'error',
              duration: 5 * 1000
            })
          }
        })
      }
    },
    hangup() {
      if (window.SPhone) {
        window.SPhone.callApi.hangup({
          success: function(res){
            console.log(res, 'hangup===')
          },
          fail: function(error) {
            console.log(error, 'error')
          }
        })
      }
    },
    holdOrUnHold(type) {
      if (window.SPhone) {
        window.SPhone.callApi.holdOrUnHold({
          type: type,
          fail: function(error) {
            console.log(error, 'error')
          }
        })
      }
    },
    muteOrUnMute(type) {
      if (window.SPhone) {
        window.SPhone.callApi.muteOrUnMute({
          type: type,
          fail: function(error) {
            console.log(error, 'error')
          }
        })
      }
    },
    accept() {
      window.SPhone.webPhoneApi.accept()
    },
    sendDTMF(key) {
      window.SPhone.webPhoneApi.sendDTMF(key)
    },
    connect() {
      window.SPhone.webPhoneApi.connect()
    },
    disconnect() {
      window.SPhone.webPhoneApi.disconnect()
    },
    keyDowndialout(event) {
      if (event.keyCode === 13) {
        this.dialout()
      }
    },
    updateLoginType(type) {
      if (type.includes('0')) {
        const typeTxt = 'SIP'
        const number = type
        window.SPhone.agentApi.updateLoginType({
          loginType: typeTxt,
          loginNumber: number,
          success: () => {
            this.loginType = typeTxt
            this.updateCurrentStatus()
            Message({
              message: '切换成功',
              type: 'success',
              duration: 5 * 1000
            })
          },
          fail: () => {
            Message({
              message: '切换失败',
              type: 'error',
              duration: 5 * 1000
            })
          }
        })
      } else {
        window.SPhone.agentApi.updateLoginType({
          loginType: type,
          success: () => {
            this.loginType = type
            this.updateCurrentStatus()
            Message({
              message: '切换成功',
              type: 'success',
              duration: 5 * 1000
            })
          },
          fail: () => {
            Message({
              message: '切换失败',
              type: 'error',
              duration: 5 * 1000
            })
          }
        })
      }
      
    },
    getAgentPhoneBarList() {
      window.SPhone.agentApi.getAgentPhoneBarList({
        success: (res) => {
          this.agentStatusList = res.data
        }
      })
    },
    updateAgentStatus() {
      window.SPhone.agentApi.updateAgentStatus({
        statusNumber: this.agentStatusNumber
      })
    },
    getAvailableSipNumberList() {
      window.SPhone.agentApi.getAvailableSipNumberList({
        success: (res) => {
          // SIP话机模式需要绑定的number参数可以从这里获取
          if (res && res.success) {
            const sipNumlist = res.data || []
            sipNumlist.forEach(item => {
              item.value = item.number
              item.label = `SIP话机(${item.numberName.substring(item.numberName.length - 4, item.numberName.length)})`
            })
            this.loginTypeList.forEach((item, index)=>{
              if (item.value === 'SIP') {
                if (!sipNumlist.length) {
                  item.label = '无SIP号'
                } else {
                  this.loginTypeList.splice(index, 1)
                  this.loginTypeList = [...this.loginTypeList, ...sipNumlist]
                }
              }
            })
          }
        }
      })
    },
    isVisibleBtn(type) {
      console.log(this.currentStatus)
      if (this.currentStatus) {
        return this.renderMap[this.currentStatus] && this.renderMap[this.currentStatus].indexOf(type) > -1
      }
    },
    getCurrentStatus(statusNumber, type) {
      const callType = type ? '_' + type.toUpperCase() : ''
      let statusName = ''
      switch (statusNumber) {
        case '0':
          statusName = this.loginType + '_INVALID'
          break
        case '1':
          statusName = this.loginType + callType + '_BUSY'
          break
        case '2':
          statusName = this.loginType + callType + '_CALLING'
          break
        case '3':
          statusName = this.loginType + callType + '_RING'
          break
        case '4':
          statusName = this.loginType + callType + '_LINK'
          break
        case '5':
          statusName = this.loginType + callType + '_ARRANGE'
          break
        case '6':
          statusName = this.loginType + callType + '_HOLD'
          break
        case '7':
          statusName = this.loginType + callType + '_MUTE'
          break
        case '8':
          statusName = this.loginType + callType + '_DISCONNECTED'
          break
        default:
          statusName = this.loginType + callType + '_BUSY'
          break
      }
      return statusName
    },
    updateCurrentStatus() {
      const statusList = this.currentStatus.split('_')
      if (statusList[0] !== this.loginType) {
        statusList[0] = this.loginType
        this.currentStatus = statusList.join('_')
      }
    },
    /**
     * 控制计时器
     * @param {string} type timing 正计时 or countDown 倒计时
     * @param {?string} timestamp 时间戳 从哪个时间开始记时 默认为函数执行时间
     */
    timeRecording(type, timestamp) {
      let second = 0
      if (timestamp) {
        const beginDate = new Date(timestamp)
        const endDate = new Date()
        const diff = endDate.getTime() - beginDate.getTime()
        second = diff / 1000
      }
      this.$refs.phoneBarTimeRef.timerTypeChange({ timeType: type, statusTime: second })
    }
  }
}
</script>

<style  scoped>
.phone-bar {
  margin: 30px;
}
.peerStatus {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.peerStatus span {
  margin-right: 10px;
}
.callStatus {
  display: flex;
}
.callBtnList {
  display: flex;
}
.dtmf {
  margin-top: 20px;
  text-align: left;
}
.dtmf-box {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}
.dtmf-box .el-button {
  margin: 0 10px 10px 0px;
}
.webphone-btn {
  margin-left: 10px;
}
</style>