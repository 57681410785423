/*
 * @Author: cuixuecheng
 * @Date: 2023-10-25 10:24:39
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2024-06-13 10:10:48
 * @Description: 
 */
import Vue from 'vue';
import App from './App.vue';
import Demo from './Demo.vue';
import Quick from './Quick.vue';
import Router from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Router);

const routes = [
  { path: '/', name: 'index', component: Demo },
  { path: '/demo', name: 'demo', component: Demo },
  { path: '/quick', name: 'quick', component: Quick },
];

const router = new Router({
  routes,
});

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
