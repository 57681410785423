<!--
 * @Author: cuixuecheng
 * @Date: 2023-10-25 11:20:26
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2024-06-13 10:08:38
 * @Description: 
-->
<template>
  <div id="demo">
    <div style="display: block;">
      <Login  @handleLogin="handleLogin" :loginSuccess="loginSuccess"/>
      <PhoneBar :loginSuccess="loginSuccess" :loginInfo="loginInfo" @handleLogin="handleLogin" />
    </div>
  </div>
</template>

<script>

import Login from './components/Login.vue'
import PhoneBar from './components/phoneBar.vue'

export default {
  name: 'DemoCom',
  components:{ Login, PhoneBar },
  data(){
    return {
      loginSuccess: false,
      loginInfo: {}
    }
  },
  methods:{
    handleLogin(res) {
      console.log(res)
      if (res) {
        this.loginSuccess = true
        this.loginInfo = res.data
      } else {
        this.loginSuccess = false
      } 
    }
  }
}
</script>

<style></style>
